/* Add this CSS to your global stylesheet */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent; /* Fully transparent track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.6); /* Semi-transparent scrollbar thumb */
  border-radius: 10px; /* Rounded edges for the thumb */
  border: 2px solid transparent; /* Adds padding to make it visually thinner */
  background-clip: padding-box; /* Ensures thumb color doesn't bleed */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.8); /* Darker thumb on hover */
}

/* Firefox styling */
.scrollable-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(136, 136, 136, 0.6) transparent;
}

