.subscription-container {
    padding: 2rem;
    background: linear-gradient(135deg, #f6f8f9 0%, #e5ebee 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center; /* Keep cards centered */
    justify-content: flex-start; /* Content starts from the top */
}

.page-title {
    text-align: center; /* Title remains centered */
    color: #333;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    width: 100%; /* Ensures the title spans the full width */
}

/* .plans-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    width: 100%;
    max-width: 800px;
} */
.plans-wrapper {
    display: flex;
    justify-content : center;
    gap: 1rem;
    width: 100%;
    /* max-width: 800px; */
    flex-wrap : nowrap
}

.plan-card {
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: left; /* Left-align content inside the card */
    justify-content: space-between;
    flex-direction: column;
    display: flex;
}


.plan-card:hover {
    transform: scale(1.05);
}

.plan-title {
    text-align: left; /* Left-align the plan title */
    color: #555;
    margin-bottom: 1rem;
}

.plan-price {
    text-align: left; /* Left-align the plan price */
    font-size: 2rem;
    color: #1976d2;
    margin-bottom: 1.5rem;
}

.feature-list {
    list-style: none;
    padding: 0;
    margin-bottom: 1.5rem;
}

.feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}

.feature-icon {
    margin-right: 0.75rem;
    font-weight: bold;
}

.feature-icon.available {
    color: green;
}

.feature-icon.unavailable {
    color: red;
}

.plan-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center; /* Keeps button text centered */
    margin-top: auto;
}

.free-button {
    background-color: #e0e0e0;
    color: #333;
}

.pro-button {
    background-color: #1976d2;
    color: white;
    margin-top: auto;
}

.plan-button.manage-button {
    background-color: #1976d2;
    color: white;
  }

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    text-align: left; /* Left-align modal content */
}

.modal-title {
    color: #1976d2;
    margin-bottom: 1rem;
}

.modal-text,
.modal-subtext {
    margin-bottom: 1rem;
    color: #555;
}

.modal-close-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .subscription-container {
        padding: 1rem;
    }

    .page-title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .plans-wrapper {
        grid-template-columns: 1fr;
    }

    .plan-card {
        padding: 1rem;
    }
}

/* Overlay Styling */
/* Overlay Styling */
/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal Box */
  .overlay-content {
    background: white;
    width: 60%;
    height: 70%;
    padding: 30px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  /* Contact Text */
  .contact-text {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
  }
  
  /* Divider */
  .divider {
    width: 80%;
    border: 1px solid #ccc;
    margin: 10px 0;
  }
  
  /* Form */
  .contact-form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
  }
  
  .form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .form-row label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-row input,
  .form-row textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Submit Button */
  button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  button[type="submit"]:disabled {
    background-color: #aaa;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Success Message */
  .success-message {
    color: green;
    font-weight: bold;
  }
  
  
  /* General Styles */
.subscription-container {
    padding: 20px;
  }
  
  .page-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .plans-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .plan-card {
    width: 30%;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .plan-card:hover {
    transform: translateY(-10px);
  }
  
  .plan-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .plan-price {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .plan-button {
    width: 100%;
    padding: 10px;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
  }
  
  .plan-button:disabled {
    background-color: #dcdcdc;
  }
  
  .overlay-content {
    padding: 20px;
  }
  
  .contact-form .form-row {
    margin-bottom: 10px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  
  .contact-form button {
    padding: 10px;
    width: 100%;
    font-size: 1rem;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .contact-form button:disabled {
    background-color: #dcdcdc;
  }
  
  .close-button,
  .modal-close-button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .plans-wrapper {
      flex-direction: column;
      align-items: center;
    }
  
    .plan-card {
      width: 90%;
      margin-bottom: 15px;
    }
  
    .plan-button {
      font-size: 1rem;
      padding: 12px;
    }
  
    .overlay-content {
      padding: 15px;
    }
  
    .modal-content {
      padding: 15px;
    }
  
    .page-title {
      font-size: 1.5rem;
    }
  }

  .plan-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    line-height: 1.4;
  }
  