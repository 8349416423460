.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: white; /* Changed background color to white */
    color: black; /* Changed text color to black */
    text-align: left; /* Aligned text to the left */
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    border: 1px solid black; /* Added black outline */
  }
  

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
