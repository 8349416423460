/* animation.css */


/* Fade-in effect for introductory text */
.fade-in {
  animation: fadeIn 2s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Style for the transparent window */
.fact-check-window {
  /* Additional styles can be added here if needed */
}


/* Keyframes for sliding in from below */
@keyframes slideInText {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInImage {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Classes to trigger animations */
.animated-text {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.5s ease-out;
}

.animated-text.slide-in-text {
  animation: slideInText 0.5s forwards;
}

.animated-image {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.5s ease-out;
}

.animated-image.slide-in {
  animation: slideInImage 0.5s forwards;
}
