/* Banner container with side slide-in animation */
.banner {
  position: fixed;
  top: 300px;
  right: 0;
  width: 40%;  /* Set the width to 40% of the screen */
  height: 80px;
  background-color: white;
  z-index: 9999;
  transition: top 0.5s ease-in-out;
  border: 2px solid #ccc;
  border-radius: 8px;
}

/* Apply animation when banner is visible */
.banner-show {
  top: 80px;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  color: black;
}

.banner-image {
  width: 40px;
  height: 40px;
}

.banner-text p {
  margin: 0;
  padding: 0 10px;
  font-size: 14px;
  word-wrap: break-word; /* Allow text to wrap if necessary */
  overflow-wrap: break-word; /* Ensure long words don't overflow */
}

.banner-close-button {
  background: none;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
}

/* Hover effects */
.banner-close-button:hover {
  color: #ff0000;
  background-color: #f1f1f1;
}

/* Media Query for Mobile Screens */
@media (max-width: 767px) {
  .banner {
    width: 90%; /* Reduce the width to fit smaller screens */
    top: 60px; /* Adjust the top position for better alignment */
    height: 70px; /* Reduce height for mobile */
  }

  .banner-show {
    top: 60px;
  }

  .banner-content {
    padding: 5px 10px;
  }

  .banner-image {
    width: 35px;
    height: 50px;
  }

  .banner-text p {
    font-size: 14px;
    padding: 0 5px; /* Adjust padding to prevent text from overflowing */
  }

  .banner-close-button {
    font-size: 20px;
    padding: 5px 10px;
  }
}

/* Media Query for Larger Screens */
@media (min-width: 768px) {
  .banner {
    top: 50px;  /* Move the banner higher */
    width: 100%;  /* Full width of the screen */
    height: 100px;
    position: fixed;
    left: 0;  /* Align to the left edge */
    right: 0; /* Align to the right edge */
    border-radius: 0;
  }

  .banner-show {
    top: 50px;  /* Adjust the top position here as well */
  }

  .banner-content {
    padding: 10px 20px;
  }

  .banner-image {
    width: 60px;
    height: 70px;
  }

  .banner-text p {
    font-size: 16px;
  }

  .banner-close-button {
    font-size: 32px;
    padding: 10px 15px;
  }
}

